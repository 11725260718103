import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import React from "react";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container className="footer-container">
        <Row>
          <Col>
            <img src={logo} alt="Logo" />
          </Col>
          <Col className="social-media-col">

            <div className="social-icon">
            
            </div>

          </Col>
        </Row>
      </Container>
    </footer>
  )
}
